import { Illustration } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CardContent, Stack } from "@mui/material";
import { Button } from "@src/appV2/redesign/components/Button";
import { useOpenGeoLocationContext } from "@src/appV2/redesign/OpenGeoLocation/useOpenGeoLocation";

import { ShiftDetailsInnerCard } from "./InnerCard";

interface ParkingCardContentProps {
  estimatedDepartureTime: string;
  estimatedTravelTime: string | undefined;
  parkingInstructions: string | undefined;
}

export function ParkingCardContent(props: ParkingCardContentProps) {
  const { estimatedDepartureTime, estimatedTravelTime, parkingInstructions } = props;
  const { href, onClick: onGetDirections } = useOpenGeoLocationContext();

  return (
    <ShiftDetailsInnerCard
      sx={{
        minWidth: 320,
        maxWidth: 320,
        borderRadius: 4,
        boxShadow: "none",
        cursor: "pointer",
        flexShrink: 0,
        mx: 1,
        "&:first-of-type": { ml: 0 },
        "&:last-of-type": { mr: 0 },
      }}
    >
      <CardContent>
        <Stack spacing={4} alignItems="center" sx={{ height: "100%", paddingX: 4, paddingY: 4 }}>
          <Illustration type="driving" />
          {isDefined(parkingInstructions) ? (
            <Text variant="h4" sx={{ textAlign: "center" }}>
              Leave home around {estimatedDepartureTime}{" "}
              {isDefined(estimatedTravelTime) ? `(${estimatedTravelTime})` : ""}
            </Text>
          ) : null}
          <Text
            variant="body2"
            sx={{ color: (theme) => theme.palette.text.secondary, textAlign: "center" }}
          >
            Parking instructions: <i>{parkingInstructions}</i>
          </Text>
        </Stack>
        <Box sx={{ marginTop: "auto", paddingTop: 8, width: "100%" }}>
          <Button
            fullWidth
            variant="outlined"
            size="medium"
            onClick={() => {
              if (onGetDirections) {
                onGetDirections();
              } else {
                window.open(href, "_blank");
              }
            }}
          >
            Get directions in maps
          </Button>
        </Box>
      </CardContent>
    </ShiftDetailsInnerCard>
  );
}
