import { Pill } from "@clipboard-health/ui-components";

import { getShiftTimingText } from "../utils/getShiftTimingText";

interface ShiftTimingPillProps {
  currentDate: Date;
  shiftStartDate: Date;
}

export function ShiftTimingPill(props: ShiftTimingPillProps) {
  const { currentDate, shiftStartDate } = props;
  const startTimingText = getShiftTimingText({ currentDate, shiftStartDate });

  return (
    <Pill
      label={startTimingText}
      variant="outlined"
      outlineColor={(theme) => theme.palette.intent?.neutral.text}
      color={(theme) => theme.palette.intent?.neutral.text}
      iconColor={(theme) => theme.palette.intent?.neutral.icon}
    />
  );
}
