import { BottomSheet, BottomSheetIllustrationContent } from "@clipboard-health/ui-components";
import { Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { Button } from "@src/appV2/redesign/components/Button";
import { useReliabilityRankingExperiment } from "@src/appV2/ReliabilityRank/useReliabilityRankExperiment";
import { useHistory } from "react-router-dom";

import { DialogFooter } from "../../../components/DialogFooter";

interface ShiftCancelledConfirmationBottomSheetProps {
  modalState: UseModalState;
  pointsDeducted: number;
  newAttendanceScore?: number;
}

export function ShiftCancelledConfirmationBottomSheet(
  props: ShiftCancelledConfirmationBottomSheetProps
) {
  const { modalState, pointsDeducted, newAttendanceScore } = props;

  const history = useHistory();

  const isClipboardScoreEnabled = useCbhFlag(CbhFeatureFlag.CLIPBOARD_SCORE_ROLLOUT, {
    defaultValue: false,
  });

  const reliabilityRankingExperiment = useReliabilityRankingExperiment({});

  const scoreType = getScoreType({
    isReliabilityRankingExperimentEnabled: reliabilityRankingExperiment.enabled,
    isClipboardScoreEnabled,
  });

  return (
    <BottomSheet modalState={modalState}>
      <BottomSheetIllustrationContent
        illustrationType="cancellation"
        title="Shift cancelled"
        description={
          <>
            You&apos;ve lost <strong>{pointsDeducted}</strong>
            {isDefined(newAttendanceScore) && `. Your ${scoreType} is now`}
          </>
        }
      />
      {isDefined(newAttendanceScore) && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ marginTop: 6 }}
        >
          <Title component="h3" variant="h3">
            {newAttendanceScore}
          </Title>
        </Stack>
      )}
      <DialogFooter orientation="vertical" sx={{ marginTop: 10 }}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={async () => {
            modalState.closeModal();
            history.push(DeprecatedGlobalAppV1Paths.MY_SHIFTS);
          }}
        >
          Continue
        </Button>
      </DialogFooter>
    </BottomSheet>
  );
}
