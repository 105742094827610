import { differenceInMinutes, startOfMinute } from "date-fns";

interface GetShiftTimingTextOptions {
  currentDate: Date;
  shiftStartDate: Date;
}

export function getShiftTimingText({
  currentDate,
  shiftStartDate,
}: GetShiftTimingTextOptions): string {
  const now = startOfMinute(currentDate);
  const shiftStart = startOfMinute(shiftStartDate);
  const diffInMinutes = differenceInMinutes(shiftStart, now);

  // Before shift starts
  if (diffInMinutes > 0) {
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;

    if (hours > 0) {
      return `Starts in ${hours}h ${minutes.toString().padStart(2, "0")} min`;
    }

    return `Starts in ${minutes.toString().padStart(2, "0")} min`;
  }

  // Shift is starting now (within 5 minutes of start time)
  if (diffInMinutes >= -5) {
    return "Starts now";
  }

  // Shift has already started (more than 5 minutes ago)
  const pastMinutes = Math.abs(diffInMinutes);
  const hours = Math.floor(pastMinutes / 60);
  const minutes = pastMinutes % 60;

  if (hours > 0) {
    return `Started ${hours}h ${minutes.toString().padStart(2, "0")} min ago`;
  }

  return `Started ${minutes.toString().padStart(2, "0")} min ago`;
}
