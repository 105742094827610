/* eslint-disable max-lines */
import { BottomSheet, TextArea } from "@clipboard-health/ui-components";
import { Text, Title, type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogContent, Stack, ToggleButton } from "@mui/material";
import { useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logError, logEvent } from "@src/appV2/lib/analytics";
import { DialogFooter } from "@src/appV2/redesign/components/DialogFooter";
import {
  type ConfirmAttendancePayload,
  useConfirmAttendance,
} from "@src/appV2/Shifts/UrgentShifts/api/useConfirmAttendance";
import { AttendanceConfirmationChannel } from "@src/appV2/Shifts/UrgentShifts/constant";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { addMinutes, format, isAfter, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";
import { z } from "zod";

import { Button } from "../../../components/Button";

interface UpdateEtaBottomSheetProps {
  modalState: UseModalState;
  shiftStartDate: Date;
  currentDate: Date;
  shiftId: string;
  workplaceId: string;
  shiftName: string;
}

const updateEtaSchema = z.object({
  arrivalTime: z.string().min(1, "Please select an arrival time"),
  message: z.string().optional(),
});

type UpdateEtaFormValues = z.infer<typeof updateEtaSchema>;

export function UpdateEtaBottomSheet(props: UpdateEtaBottomSheetProps) {
  const { modalState, shiftStartDate, currentDate, shiftId, workplaceId, shiftName } = props;
  const sendBirdStateContext = useSendbirdStateContext();
  const worker = useDefinedWorker();
  const { showSuccessToast, showErrorToast } = useToast();
  const history = useHistory();
  const agentTimezone = worker.tmz ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { mutateAsync: onConfirmAttendance } = useConfirmAttendance();

  const arrivalTimeOptions = useMemo(() => {
    const timeIncrements = [5, 10, 15, 30, 45, 60];
    let baseTime: Date;

    if (isAfter(currentDate, shiftStartDate)) {
      // If current time is after shift start time
      // Round up to next 5 minute interval
      const minutes = currentDate.getMinutes();
      const roundedMinutes = Math.ceil(minutes / 5) * 5;

      // Use date-fns to create and modify dates
      baseTime = addMinutes(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          currentDate.getHours(),
          0,
          0,
          0
        ),
        roundedMinutes
      );
    } else {
      // If current time is before shift start time
      baseTime = new Date(
        shiftStartDate.getFullYear(),
        shiftStartDate.getMonth(),
        shiftStartDate.getDate(),
        shiftStartDate.getHours(),
        shiftStartDate.getMinutes(),
        0,
        0
      );
    }

    return timeIncrements.map((minutes) => {
      const time = addMinutes(baseTime, minutes);
      return {
        value: format(time, "h:mma"),
        displayTime: format(time, "h:mma"),
        date: time,
      };
    });
  }, [currentDate, shiftStartDate]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<UpdateEtaFormValues>({
    resolver: zodResolver(updateEtaSchema),
    defaultValues: {
      arrivalTime: arrivalTimeOptions.length > 0 ? arrivalTimeOptions[0].value : "",
      message: "",
    },
  });

  const selectedTime = watch("arrivalTime");

  function showEtaUpdateSuccessToast(params: {
    shiftStart: string;
    agentTimezone: string;
    shiftName: string;
  }) {
    const { shiftStart, agentTimezone, shiftName } = params;
    showSuccessToast(
      `You successfully updated your ETA for your ${formatInTimeZone(
        parseISO(shiftStart),
        agentTimezone,
        "MM/dd"
      )} ${shiftName.toUpperCase()} shift`
    );
  }

  async function onConfirmAttendanceSuccess(params: {
    payload: ConfirmAttendancePayload;
    messageToFacility: string;
  }) {
    const { payload, messageToFacility } = params;
    const sdk = sendBirdStateContext?.stores?.sdkStore?.sdk;
    const sendUserMessage = sendBirdSelectors.getSendUserMessage(sendBirdStateContext);

    showEtaUpdateSuccessToast({
      shiftStart: shiftStartDate.toISOString(),
      agentTimezone,
      shiftName,
    });

    if (sdk?.UserMessageParams && isDefined(payload.workplaceId)) {
      const params = new sdk.UserMessageParams();
      params.message = messageToFacility;
      await sendUserMessage(`${payload.workplaceId}_${worker.userId}`, params);
      history.push(`/home/account/chat/${payload.workplaceId}`);
    }

    logEvent(APP_V2_USER_EVENTS.SUBMITTED_ETA_MODAL, {
      shiftId: payload.shiftId,
      workerId: worker.userId,
      facilityId: payload.workplaceId,
      channel: payload.channel,
      shiftStart: shiftStartDate.toISOString(),
      createdAt: new Date().toISOString(),
      etaSubmitted: payload.eta,
      messageToFacility,
    });

    modalState.closeModal();
  }

  const onSubmit = handleSubmit(async (data: UpdateEtaFormValues) => {
    const selectedTimeOption = arrivalTimeOptions.find(
      (option) => option.value === data.arrivalTime
    );

    if (!selectedTimeOption) {
      showErrorToast("Please select an arrival time");
      return;
    }

    const messageToFacility = `Hi, I'm running late to my ${format(
      shiftStartDate,
      "h:mm a"
    )} ${shiftName} shift. I will arrive by ${data.arrivalTime}.${
      data.message ? ` ${data.message}` : ""
    }`;

    const payload: ConfirmAttendancePayload = {
      shiftId,
      eta: selectedTimeOption.date.toISOString(),
      channel: AttendanceConfirmationChannel.MY_SHIFT_PAGE,
      workplaceId,
    };

    try {
      await onConfirmAttendance(payload);
      await onConfirmAttendanceSuccess({ payload, messageToFacility });
    } catch (error) {
      showErrorToast("Something went wrong while updating your ETA. Please try again.");
      logError("Error submitting ETA update", {
        error,
        metadata: {
          shiftId,
          facilityId: workplaceId,
        },
      });
    }
  });

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            disabled={isSubmitting}
            endIconType="paper-airplane"
            onClick={onSubmit}
          >
            Send message
          </Button>
        </DialogFooter>
      }
    >
      <DialogContent>
        <Stack spacing={8} sx={{ paddingTop: 8 }}>
          <Stack spacing={4}>
            <Title semibold variant="h3" component="h3">
              I&apos;m running late
            </Title>
            <Text variant="body2">Let the workplace know when you will arrive.</Text>
          </Stack>

          <Stack spacing={6}>
            <Text semibold>I will arrive at</Text>
            <Controller
              name="arrivalTime"
              control={control}
              render={({ field }) => (
                <Stack direction="row" flexWrap="wrap" sx={{ gap: 4 }}>
                  {arrivalTimeOptions.map((option) => (
                    <ToggleButton
                      key={option.value}
                      value={option.value}
                      sx={{ minWidth: "auto", px: 2, flex: "1 0 30%" }}
                      selected={field.value === option.value}
                      onChange={(_, value) => {
                        if (value !== null) {
                          field.onChange(value);
                        }
                      }}
                    >
                      {option.displayTime}
                    </ToggleButton>
                  ))}
                </Stack>
              )}
            />
          </Stack>

          <Stack spacing={4}>
            <Text semibold>Your message</Text>
            <Text sx={{ fontStyle: "italic" }}>
              Hi, I&apos;m running late to my {format(shiftStartDate, "h:mm a")} {shiftName} shift.
              I will arrive by {selectedTime}.
            </Text>
          </Stack>

          <Stack spacing={4}>
            <Text semibold>Additional message (optional)</Text>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextArea fullWidth rows={3} placeholder="Provide additional details" {...field} />
              )}
            />
          </Stack>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
/* eslint-enable max-lines */
